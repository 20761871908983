import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import List from "../components/list"
import Paginate from "../components/Paginate"
import { PostMdl } from "/src/cchelper"
import "../styles/PostListTemplate.scss"

const PostListTemplate = ({ pageContext, location }) => {
  var {
    category,
    posts,
    numPages,
    currentPage,
    missingImg,
    menuData,
  } = pageContext
  var edges = pageContext.posts
  posts = edges.map(item => {
    return new PostMdl(item)
  })

  category = pageContext.category ? pageContext.category : ""

  return (
    <Layout menuData={menuData} location={location}>
      <Seo title={"Posts " + category} />
      <List lista={posts} prefix={category} missingImg={missingImg}></List>
      <Paginate
        numPages={numPages}
        prefix={category}
        current={currentPage}
      ></Paginate>
    </Layout>
  )
}

export default PostListTemplate

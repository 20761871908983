import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import "../styles/PostListItem.scss"
import TagList from "./TagList"

export default function PostListItem(props) {
  var { post, missingImg } = props
  var p = post
  // console.log(p)
  //   var img = has(p, "image.image.childImageSharp.fluid")
  //     ? p.image.image.childImageSharp.fluid
  //     : missingImg.fluid
  var img = p.image ? getImage(p.image.image) : getImage(missingImg)
  console.log("IMG", img)
  var to = "/" + p.slug
  var excerpt = p.excerpt

  const art = (
    <article className="post-item">
      <h3 className="title">
        <Link to={to}>{p.title}</Link>
      </h3>

      <div className="image">
        <Link to={to}>
          <GatsbyImage alt="" image={img} objectFit="cover" />
          {/* <img src={p.image.url} alt="post images" /> */}
        </Link>
      </div>

      <div className="date">{p.date}</div>

      <TagList className="tags" prefix={"tag"} tags={p.tags}></TagList>

      <div
        className="excerpt"
        dangerouslySetInnerHTML={{ __html: excerpt }}
      ></div>
    </article>
  )
  return art
}

import React from "react"
import PostListItem from "./PostListItem"
import "../styles/list.scss"

export default function List(props){
  var {prefix='', missingImg} = props;
  const lista = props.lista.map((post, index) =>{
    //var imageurl = get(item, 'image.url', missingImg);
    var res = (
      // title, date, image, excerpt, tags
        <PostListItem prefix={prefix} post={post} key={index} missingImg={missingImg}/>
    );
    return res;
  })


  return <div className='post-list'>{lista}</div>
}
